var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.systemComponent.systemComponentImagePath,
            _vm.systemComponent.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.systemComponent.systemComponentImageIsDefault &&
          _vm.hasSystemComponentDeleteImage},on:{"changeValue":function($event){_vm.systemComponent.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.systemComponent.fullCode,"title":_vm.$t('SystemComponents.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.systemComponent.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-systemComponentNameAr"),"errors":_vm.errors_systemComponentNameAr,"value":_vm.systemComponent.systemComponentNameAr,"title":_vm.$t('SystemComponents.nameAr'),"imgName":'systemComponents.svg'},on:{"changeValue":function($event){_vm.systemComponent.systemComponentNameAr = $event;
            _vm.$v.systemComponent.systemComponentNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-systemComponentNameEn"),"errors":_vm.errors_systemComponentNameEn,"value":_vm.systemComponent.systemComponentNameEn,"title":_vm.$t('SystemComponents.nameEn'),"imgName":'systemComponents.svg'},on:{"changeValue":function($event){_vm.systemComponent.systemComponentNameEn = $event;
            _vm.$v.systemComponent.systemComponentNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-systemComponentNotes"),"value":_vm.systemComponent.systemComponentNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.systemComponent.systemComponentNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }